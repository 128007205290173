.brands-container{
  p{
    text-align: center;
  }
  padding-bottom: 80px;
  margin-left: auto;
  margin-right: auto;
  .row{
    text-align: center;
    align-items: center;
    .col{
      align-self: center;
    }
  }
}
.homepage-content{
  padding-top: 80px;
  padding-bottom: 80px;
}
.logo-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 230px;
  justify-content: space-around;
  &-image{
    height: 150px;
    margin-bottom: 20px;
  }
img{
  max-width:100%;
  max-height:100%;

}
}
.bmw-button{
  color:white !important;
  background-color:#007BFF !important;
}
.mini-button{
  color:white !important;
  background-color:#EC9603 !important;
}
@media screen and(max-width: 767px){
  .brands-container{
    .row{
      img:last-child{
        margin-top:20px !important;
      }
      .col:last-child{
        margin-top: 40px;
      }
    }
  }

}

/* This fires as soon as the element enters the dorm */
.list-transition-enter,
.list-transition-appear {
  /*We give the list the initial dimension of the list button*/
  opacity: 0.1;
  color: transparent;

}
/* This is where we can add the transition*/
.list-transition-enter-active{
  opacity: 0.3 ;
  transition: all 300ms;
}
.list-transition-appear-active {
  opacity: 1 ;
  transition: all 500ms;
}

/* This fires as soon as the this.state.showList is false */
.list-transition-exit {

}
/* fires as element leaves the DOM*/
.list-transition-exit-active {
  top: 0;
  width: 120px;
  max-height: 40px;
  color: transparent;
  transition: all 400ms;
}

