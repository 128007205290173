#header{
  justify-content: space-between;
  align-items: center;
  display: flex;
  min-height: 90px;
  a{
    cursor: pointer !important;
  }
  .logo{
    text-align: center;
    .jorssen{
      height: fit-content;
      object-fit: cover;
      width: 210px;
      margin-top:7px;
      float:left;
    }
  }
}

@media screen and(min-width: 800px) {
#header{
  .logo{
    margin-bottom: 0;
  }
}
}

@media screen and(max-width: 420px) {

  #header{
    .container{
      width: 100%;
      margin-left: unset;
      margin-right: unset;
    }
   .logo{
     .jorssen{
     }
   }
  }
}
