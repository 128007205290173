.footerContainer{
  a{
    color:white;
    &:hover{
      color:#EC9603;
    }
  }
}
.footerContainerLight{

  div{
    color:#0275d8
  }
}
